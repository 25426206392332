<template>
    <Layout >
        <CardsDashboard/>

        <Graphic/>

        <div class="row">

            <div class="col-12 mt-3">

                <div class="page-separator">
                    <div class="page-separator__text">Minhas Mentorias</div>
                </div>

                <ListCourses class="v-step-1"/>
            </div>

            <div class="col-12">

                <div class="page-separator">
                    <div class="page-separator__text v-step-2">To-do list</div>
                </div>

                <Activities/>

            </div>
        </div>
        <v-tour name="myTour" :steps=" steps " :options="tourOptions"></v-tour>
    </Layout>
</template>

<script>
import Layout from "@/components/layout/main.vue";
import ListCourses from "./list-courses.vue";
import CardsDashboard from "./cards-dashboard.vue";
import Graphic from "./graphic.vue";
import Activities from './activities.vue'

export default {
    components: {
        Layout,
        ListCourses,
        CardsDashboard,
        Graphic,
        Activities
    },
    // name: 'my-tour',
    data() {
        return {
            steps: [
                {
                    target: '.v-step-0',
                    header: {
                        title: 'Início',
                    },
                    content: `Aqui estão os dados resumidos de sua mentoria`,
                    params: {
                        placement: 'top'
                    }
                },
                {
                    target: '.v-step-1',
                    header: {
                        title: 'Meus Cursos',
                    },
                    content: 'Aqui temos os seus cursos cadastrados',
                    params: {
                        placement: 'top'
                    }
                },
            ],
            tourOptions: {
                labels: {
                    buttonSkip: "Pular",
                    buttonPrevious: "Anterior",
                    buttonNext: "Próximo",
                    buttonStop: "Parar"
                }
            },
        }
    },

    mounted() {
        // if (this.$tours && this.$tours['myTour']) {
        //     this.$tours['myTour'].start();
        // }
    }
}
</script>
