<template>
    <TableList
        :session="session" :table="table" :keys="keys"
    >

        <template #title="{value}">
            {{ value.section.mentoring.title }}
        </template>

        <template #mentee="{value}">
            {{ value.mentee.name }}
        </template>

        <template #resolved_at="{value}">
            {{ convertDateText(value.resolved_at) }} {{ convertHour(value.resolved_at) }}
        </template>

        <template #time_mentor="{value}">
            {{ convertDateText(value.time_mentor) }}
        </template>

        <template #actions="{value}">
            <router-link :to="`mentorias/detalhes/${value.section.mentoring.id}/mentorando/${value.mentee.id}`">
                <i class="fa fa-eye text-muted mx-1" title="Visualizar"></i>
            </router-link>
        </template>


    </TableList>
</template>


<script>
import {convertDateText, convertHour} from "@/components/composables/masks";
import TableList from "@/components/base/table-list.vue";

export default {
    props: {
        session: {
            type: String,
            required: true
        }
    },
    components: {
        TableList
    },
    data() {
        return {
            activities: [],
            list_active: 'application_form',
            table: [
                {column: 'Mentoria'},
                {column: 'Mentorado'},
                {column: 'Data Realização'},
                {column: 'Retorno Previsto'},
                {column: 'Ações'}
            ],
            keys: ['title', 'mentee', 'resolved_at', 'time_mentor', 'actions']
        }
    },

    methods: {
        convertDateText,
        convertHour,
    },
}

</script>
