<template>
    <div class="row">
        <div class="col-12 mt-4">

            <div class="page-separator">
                <div class="page-separator__text">Receitas</div>
            </div>
            <div class="card card-body mb-32pt">
                <div id="legend" class="chart-legend mt-0 mb-24pt justify-content-end">
                    <span class="chart-legend-item pointer" @click="setType(1)">
                        <i class="chart-legend-indicator" :style="`background-color: ${type === 1 ? '#2196F3' : '#FFF'}`"></i> Últimos 6 meses
                    </span>
                    <span class="chart-legend-item pointer" @click="setType(2)">
                        <i class="chart-legend-indicator" :style="`background-color: ${type === 2 ? '#2196F3' : '#FFF'}`"></i> Últimos 12 meses
                    </span>
                    <span class="chart-legend-item pointer" @click="setType(3)">
                        <i class="chart-legend-indicator" :style="`background-color: ${type === 3 ? '#2196F3' : '#FFF'}`"></i> Últimos 24 meses
                    </span>
                </div>
                <div id="chart">
                    <apexchart class="apex-charts" height="350" dir="ltr" :series="getSeries" :options="chartOptions">
                    </apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/http";

export default {
    data() {
        return {
            series: [
                {
                    name: 'Vendas por mês',
                    type: 'column',
                    data: []
                }, {
                    name: 'Mentorados',
                    type: 'line',
                    data: []
                }
            ],
            chartOptions: {
                noData: {
                    text: 'Loading...'
                },
                chart: {
                    height: 350,
                    type: 'line',
                },
                stroke: {
                    width: [0, 4]
                },
                // title: {
                //     text: 'Traffic Sources'
                // },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                labels: [],
                yaxis: [{
                    title: {
                        text: 'Vendas por mês',
                    },

                }, {
                    opposite: true,
                    title: {
                        text: 'Mentorados'
                    }
                }]
            },
            type: 1
        }
    },

    methods: {
        getChartData() {
            let self = this
            http.get('mentors/dashboard-chart/' + this.type)
                .then(response => {

                    self.series = [
                        {
                            name: 'Vendas por mês',
                            type: 'column',
                            data: response.data['value_by_month']
                        }, {
                            name: 'Mentorados',
                            type: 'line',
                            data: response.data['count_by_month']
                        }
                    ]

                    self.chartOptions = {
                        noData: {
                            text: 'Loading...'
                        },
                        chart: {
                            height: 350,
                            type: 'line',
                        },
                        stroke: {
                            width: [0, 4]
                        },
                        dataLabels: {
                            enabled: true,
                            enabledOnSeries: [1]
                        },
                        labels: response.data['labels'],
                        yaxis: [
                            {
                                title: {
                                    text: "Vendas por mês",
                                },
                                labels: {
                                    forceNiceScale: true,
                                },
                                tickAmount: 5,
                            },
                            {
                                opposite: true,
                                title: {
                                    text: "Mentorados",
                                },
                                labels: {
                                    forceNiceScale: true,
                                    tickAmount: 5,
                                },
                            },
                        ],
                    }
                })
                .catch((e) => {
                    console.error(e);
                    // this.$notifyError('Erro ao excluir Módulo!');
                })
        },

        setType(newValue) {
            this.type = newValue;
            this.getChartData()
        }
    },

    computed: {
        getSeries() {
            return this.series
        }
    },

    mounted() {
        this.getChartData()
    }
}
</script>
