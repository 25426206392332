<!--eslint-disable no-mixed-spaces-and-tabs-->
<template>
    <div>
        <b-card-body>
            <div class="row align-items-center mt-4">
                <div class="col-auto">
                    <span>Exibir </span>
                </div>
                <div class="col-auto">
                    <select class="form-control form-select" id="limitFilter"
                            @change="setLimit">
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                    </select>
                </div>
                <div class="col-auto">
                    <span> registros</span>
                </div>
            </div>

            <div class="row">
                <div class="col mt-5">
                    <p>Exibindo de <span id="start">{{ listAll.start }}</span> a <span id="partial">{{
                            listAll.partial
                        }}</span> de
                        <span id="count">{{ listAll.count }}</span> registros</p>
                </div>
            </div>

            <div class="live-preview">
                <div class="table-responsive-xl mb-4">
                    <table class="table table-striped align-middle mb-0" id="table" style="position: relative">
                        <thead>
                        <tr>

                            <!--                            Gerar as colunas do cabeçalho das tables-->
                            <th class="text-center" :class="thead.order ? 'relative' : ''"
                                v-for="(thead, index) in table" :key="'th' + index">
                                {{ thead.column }}
                                <i v-if="thead.order" class="ri-arrow-drop-up-fill pointer"
                                   :class="thead.order === order_by && order ==='asc' ? 'active' : ''"
                                   @click="newOrder(thead.order, 'asc')"></i>
                                <i v-if="thead.order" class="ri-arrow-drop-down-fill pointer"
                                   :class="thead.order === order_by && order ==='desc' ? 'active' : ''"
                                   @click="newOrder(thead.order,  'desc')"></i>
                            </th>

                        </tr>
                        </thead>

                        <!--                        Se encontrar resultados da api gerar as linhas-->
                        <tbody v-if="listAll.data && Object.keys(listAll.data).length > 0">
                        <tr v-for="(line, i) in listAll.data" :key="'tr' + line.id + i" :id="'line' + line.id">
                            <td class="text-center" v-for="(k, tdIndex) in keys" :key="'td' + tdIndex">

                                <slot :name="k" :value="line">
                                    {{ line[k] }}
                                </slot>
                            </td>
                        </tr>
                        </tbody>

                        <!--                        Se não mensagem de nada encontrado-->
                        <tbody v-else-if="listAll.page !== '' && listAll.count === 0">
                        <tr>
                            <td :colspan="Object.keys(table).length" class="text-center">Nenhum resultado
                                encontrado
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row" v-if="loadTable">
                    <div class="col text-center">
                    <span class="spinner spinner-border flex-shrink-0" id="spinnerTable" role="status">
                        <span class="visually-hidden"></span>
                    </span>
                    </div>
                </div>
            </div>
        </b-card-body>
        <div class="card-footer p-8pt">
            <ul class="pagination justify-content-start pagination-xsm m-0 float-right">
                <li class="page-item" :class="{disabled: currentPage == 1}"
                    @click="paginator(listAll.page - 1)">
                    <div class="page-link pointer"
                         aria-label="Previous">
                                                    <span aria-hidden="true"
                                                          class="material-icons">chevron_left</span>
                        <span>Anterior</span>
                    </div>
                </li>
                <li v-for="n in listAll.pages" :key="n" class="page-item">
                    <div @click="paginator(n)" class="page-link pointer"
                         :class="{active: n == currentPage}"
                         aria-label="Page 1">
                        <span>{{ n }}</span>
                    </div>
                </li>
                <li class="page-item" :class="{disabled: currentPage == listAll.pages}"
                    @click="paginator(listAll.page + 1)">
                    <div class="page-link pointer"
                         aria-label="Next">
                        <span>Próximo</span>
                        <span aria-hidden="true"
                              class="material-icons">chevron_right</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {getUrl} from "@/components/composables/functions";
import {setSessions} from "@/components/composables/setSessions";
import http from "@/http";
import env from "@/env";
import {endLoadTable, loadTable} from "@/components/composables/spinners";
import {EventBus} from "@/event-bus";

export default {
    props: [
        'session', 'table', 'keys', 'url'
    ],
    data() {
        return {
            loadTable: false,
            env,
            currentPage: '',
            listAll: {
                data: [],
                start: 0,
                partial: 0,
                total: 0,
                page: '',
                pages: [1]
            }
        };
    },

    methods: {
        newOrder(new_order_by, new_order) {
            let obj = JSON.parse(localStorage.getItem(this.session))

            obj.params.order = new_order;
            this.order = new_order;

            obj.params.order_by = new_order_by;
            this.order_by = new_order_by;

            localStorage.setItem(this.session, JSON.stringify(obj));

            const url = getUrl(this.session)
            console.log(url)
            this.$store.dispatch('api/getApi', url);

        },

        paginator(newPage) {
            if (this.currentPage !== newPage && newPage > 0) {
                this.getData(newPage);
            }
        },

        // Busca dos dados da tabela na store
        getData(newPage) {

            this.loadTable = true;
            if (!localStorage.getItem(this.session)) {
                setSessions(this.session);
                setTimeout(() => {
                    this.getData();
                    return;
                }, 200)
            } else {
                let obj = JSON.parse(localStorage.getItem(this.session));
                this.currentPage = newPage || 1;
                obj.params.page = newPage || 1;
                sessionStorage.setItem(this.session, JSON.stringify(obj));
                let url = getUrl(this.session);

                http.get(url)
                    .then(response => {
                        const newValue = response.data;

                        let page = newValue.page || 0;
                        const limit = document.getElementById('limitFilter') ? document.getElementById('limitFilter').value : 0;
                        const count = newValue.count || 0;
                        newValue.start = count > 0 ? page * limit + 1 : 0;
                        newValue.partial = count <= (page + 1) * limit ? count : (page + 1) * limit;

                        ++newValue.page;
                        ++page;
                        this.listAll = newValue

                        this.listAll.pages = [];

                        if (page <= 5) {
                            for (let i = 1; i <= page; i++) {
                                this.listAll.pages.push(i);
                            }
                        } else if (page < this.total - 5) {
                            for (let i = page - 2; i <= page + 2; i++) {
                                this.listAll.pages.push(i);
                            }
                        } else {
                            for (let i = this.total - 5; i <= this.total; i++) {
                                this.listAll.pages.push(i);
                            }
                        }


                        endLoadTable();
                        if (document.getElementById('spinnerTable')) document.getElementById('spinnerTable').style.display = 'none';
                        if (document.getElementById('spinnerCard')) document.getElementById('spinnerCard').style.display = 'none';
                    })
                    .catch(error => {
                        endLoadTable();
                        console.error(error);
                        // Forbidden(error);
                    })
                    .finally(() => {
                        this.loadTable = false;
                    })
            }
        },

        // Settar novo valor de limit na localStorage e chamando nova busca na store
        setLimit() {
            loadTable();
            let obj = JSON.parse(localStorage.getItem(this.session));
            obj.params.page = 1;
            obj.params.limit = document.getElementById('limitFilter').value;
            localStorage.setItem(this.session, JSON.stringify(obj));
            const data = {
                page: 1,
                limit: document.getElementById('limitFilter').value
            }
            this.$store.commit('api/setPagination', data);
            this.getData();
        },

        newView(type) {
            setSessions(this.session);
            this.$store.commit('api/resetValues', {})
            localStorage.setItem('listType', type);
            if (type === 'table') this.getData();
        },
    },

    mounted() {
        this.getData(1);
    },

    created() {
        EventBus.$on('myEvent', this.getData);
    },
    beforeDestroy() {
        EventBus.$off('myEvent', this.getData);
    }
}
;

</script>

<style>

th i {
    opacity: .4 !important;
}

th i.active {
    opacity: 1 !important;
}

.icon-list {
    border: solid 1px #C6C6C6;
    border-radius: 5px;
}


</style>
