<template>
    <div class="card mb-32pt">
        <div class="card-header p-0 nav">
            <div class="row no-gutters" role="tablist">

                <div class="col-auto">
                    <div
                        @click="list_active = 'application_form'"
                        class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                        :class="list_active === 'application_form' ? 'active' : ''"
                    >
                                    <span class="flex d-flex">
                                        <strong>Formulário de Aplicação</strong>
                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{ accountants.application_forms }}</span>
                                    </span>
                    </div>
                </div>

                <div class="col-auto">
                    <div
                        @click="list_active = 'forms'"
                        class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                        :class="list_active === 'forms' ? 'active' : ''"
                    >
                                    <span class="flex d-flex">
                                        <strong>Formulário de Módulo</strong>
                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{ accountants.forms }}</span>
                                    </span>
                    </div>
                </div>

                <div class="col-auto">
                    <div
                        @click="list_active = 'schedule'"
                        class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                        :class="list_active === 'schedule' ? 'active' : ''"
                    >
                                    <span class="flex d-flex">
                                        <strong>Agenda</strong>
                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{ accountants.schedules }}</span>
                                    </span>
                    </div>
                </div>

                <div class="col-auto">
                    <div
                        @click="list_active = 'activity'"
                        class="dashboard-area-tabs__tab card-body d-flex flex-row align-items-center justify-content-start pointer"
                        :class="list_active === 'activity' ? 'active' : ''"
                    >
                                    <span class="d-flex">
                                        <strong>Atividade</strong>
                                        <span class="ml-3 badge badge-notifications badge-primary text-white">{{ accountants.activities }}</span>
                                    </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-body">

            <TableList v-show="list_active === 'application_form'"
                       session="ApplicationFormDashboard"
                       :table="table" :keys="keys"
            >

                <template #title="{value}">
                    {{ value.mentoring.title }}
                </template>

                <template #mentee="{value}">
                    {{ value.name }}
                </template>

                <template #resolved_at="{value}">
                    {{ convertDateText(value.task.resolved_at) }} {{ convertHour(value.task.resolved_at) }}
                </template>

                <template #time_mentor="{value}">
                    {{ convertDateText(value.task.time_mentor) }}
                </template>

                <template #actions="{value}">
                    <router-link :to="value.task.url">
                        <i class="fa fa-eye text-muted mx-1" title="Visualizar"></i>
                    </router-link>
                </template>

            </TableList>
            <ActivitiesTables v-show="list_active === 'forms'" session="FormDashboard"/>
            <ActivitiesTables v-show="list_active === 'schedule'" session="ScheduleDashboard"/>
            <ActivitiesTables v-show="list_active === 'activity'" session="ActivityDashboard"/>

        </div>


    </div>
</template>

<script>

import ActivitiesTables from "@/views/dashboard/activities-tables.vue";
import TableList from "@/components/base/table-list.vue";
import http from "@/http";
import {convertDateText, convertHour} from "../../components/composables/masks";

export default {
    components: {
        ActivitiesTables,
        TableList
    },
    data() {
        return {
            list_active: 'application_form',
            accountants: {
                forms: 0,
                application_forms: 0,
                schedules: 0,
                activities: 0
            },
            table: [
                {column: 'Mentoria'},
                {column: 'Mentorado'},
                {column: 'Data Realização'},
                {column: 'Retorno Previsto'},
                {column: 'Ações'}
            ],
            keys: ['title', 'mentee', 'resolved_at', 'time_mentor', 'actions']
        }
    },

    methods: {
        convertDateText,
        convertHour,
        getAccountants() {
            http.get('tasks/accountants')
                .then(response => {
                    this.accountants = response.data;
                })
                .catch(e => {
                    console.error('get Accountants: ', e);
                })
        }
    },

    mounted() {
        localStorage.removeItem('ApplicationFormDashboard');
        this.getAccountants();
    }

}

</script>
