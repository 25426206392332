<template>

    <div>
        <div class="row card-group-row" v-if="courses.count && courses.count > 0">

            <div class="col-sm-6 col-md-3 card-group-row__col" v-for="course in courses.data"
                 :key="'course' + course.id">

                <div
                    class="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card"
                    data-toggle="popover" data-trigger="click" data-original-title="" title=""
                    data-domfactory-upgraded="overlay">

                    <router-link :to="'/mentorias/detalhes/' + course.id">
                        <div class="card-img-top js-image" data-position="center" data-domfactory-upgraded="image">
                            <img :src="course.photo"
                                 class="mentoring-image"
                                 alt="course">
                        </div>
                    </router-link>

                    <div class="card-body flex">
                        <div class="d-flex">
                            <div class="flex">
                                <router-link :to="'/mentorias/detalhes/' + course.id">
                                    <div class="card-title">
                                        {{ course.title }}
                                    </div>
                                    <small class="text-50 font-weight-bold mb-4pt">
                                        {{ course.type_id }}
                                    </small>
                                </router-link>
                            </div>
                            <a data-toggle="tooltip"
                               data-title="Add Favorite" data-placement="top"
                               data-boundary="window"
                               class="ml-4pt material-icons text-20 card-course__icon-favorite"
                               data-original-title="" title="">favorite_border</a>
                        </div>
                        <div class="d-flex">
                            <div class="rating flex">
                                <span class="rating__item"><span
                                    class="material-icons">star</span></span>
                                <span class="rating__item"><span
                                    class="material-icons">star</span></span>
                                <span class="rating__item"><span
                                    class="material-icons">star</span></span>
                                <span class="rating__item"><span
                                    class="material-icons">star</span></span>
                                <span class="rating__item"><span
                                    class="material-icons">star_border</span></span>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row justify-content-between">
                            <div class="col-auto d-flex align-items-center">
                                <i class="fa fa-users icon-16pt text-50 mr-4pt"></i>
                                <p class="flex text-50 lh-1 mb-0"><small>
                                    <b>{{ course.total_mentees }} mentorados</b>
                                </small></p>
                            </div>
                            <div class="col-auto d-flex align-items-center">
                                <p class="flex text-50 lh-1 mb-0"><small>
                                    {{
                                        parseFloat(course.price).toLocaleString('pt-BR', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })
                                    }}
                                </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-else>
            <div class=" col-lg-12 col-sm-12 col-md-3 card-group-row__col">

                <div
                    class="card card-sm card--elevated p-relative o-hidden overlay overlay--primary-dodger-blue js-overlay card-group-row__card">

                    <router-link to="/mentorias/cadastrar">
                        <div class="card-img-top js-image" data-position="center" data-domfactory-upgraded="image">
                            
                        </div>
                    </router-link>

                    <div class="card-body flex">
                        <div class="d-flex">
                            <div class="flex">
                                <router-link to="/mentorias/cadastrar">
                                    <div class="card-title">
                                        Cadastre sua mentoria aqui
                                    </div>
                                </router-link>
                            </div>
                            
                        </div>
                       
                    </div>
                    
                </div>
            </div>
        </div>

        <div class="row" v-if="courses.count && courses.count > 0">
            <div class="col text-center mb-3">
                <router-link to="/mentorias" class="text-link">
                    Ver mais
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    data() {
        return {}
    },

    methods: {

        loadList() {
            this.$store.dispatch('api/getApi', 'mentorings?limit=3&status=1');
        },
    },

    mounted() {
        // chamada de busca de dados na store
        this.loadList();
    },

    computed: {
        courses: {
            get() {
                return this.$store.state.api.listAll ?? {};
            },
        },
    }
}
</script>

<style scoped>

.card-img-top.js-image {
    display: block;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
}

.mentoring-image{
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
}
</style>
